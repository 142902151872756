export const walletAddressFormat = (wallet) => {
  return (
    wallet?.substring(0, 4) +
    "..." +
    wallet?.substring(wallet.length - 4, wallet.length)
  );
};

export const uniqueId = (length = 10) => {
  return Math.random().toString(36).substring(2, length + 2);
};
