// Chakra imports
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  LightMode,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAws, FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import pledgingABI from "utils/pledgingABI.json";
import Pusher from "pusher-js";

import {
  BrowserProvider,
  Contract,
  formatUnits,
  MaxUint256,
  parseEther,
  parseUnits,
  toNumber,
} from "ethers";
export default function StakingReport({ data }) {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [rewards, setRewards] = useState([]);
  const [stakeInformation, setStakeInformation] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [active, setActive] = useState("");
  const [buttonColor, setButtonColor] = useState("rgb(0,121,107)");
  const [buttonHoverColor, setButtonHoverColor] = useState("#00877a");

  let configData = useContext(ConfigContext);
  const config = configData?.config;
  const authData = useContext(AuthContext);
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("rgb(0,121,107)");
      setButtonHoverColor("#00877a");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);
  const { setModal } = authData;

  const onClose = () => {
    setActive("");
  };

  const handleUnstakeClick = () => {
    setSubmitLoading(true);
    userPrivateRequest
      .post("/staking/submitWithdraw")
      .then((response) => {
        console.log(response);
        fetchStakeInformation();
        toast.success("Withdrawal request submitted successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Withdrawal request failed");
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  let creditLabel =
    config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";
  const SI = config?.global?.STAKING_INFORMATION?.value;
  const overviewTitle = isKor ? SI?.pledgingOverviewKr : SI?.pledgingOverview;
  const staked = isKor ? SI?.stakedKr : SI?.staked;
  const daysRemaining = isKor ? SI?.daysRemainingKr : SI?.daysRemaining;
  const withdraw = isKor ? SI?.unstakeKr : SI?.unstake;
  const fetchStakeInformation = async () => {
    const result = await userPrivateRequest
      .get("/staking/stakingOverview")
      .then((response) => {
        setStakeInformation(response?.data?.data ?? {});
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    // fetchRewardHistories();
    fetchStakeInformation();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const [claimLoading, setClaimLoading] = useState(false);
  const [pendingHash, setPendingHash] = useState("");
  const handleClaim = async (withdraw) => {
    if (window.ethereum) {
      try {
        setClaimLoading(true);
        const pledgingContractAddress =
          process.env.REACT_APP_PLEDGING_CONTRACT_ADDRESS;

        let ethersProvider = new BrowserProvider(window.ethereum);
        let signer = await ethersProvider.getSigner();
        // Initialize contracts
        const pledgingContract = new Contract(
          pledgingContractAddress,
          pledgingABI.abi,
          signer
        );
        // Make withdrawal
        let transaction = await pledgingContract.withdraw();
        toast.info("Processing withdrawal...");
        await transaction.wait();
        console.log("transaction", transaction);
        let payload = {
          id: withdraw._id,
          hash: transaction.hash,
        };
        // payload = await encryptTransaction(payload);
        // console.log(payload);
        userPrivateRequest
          .post("/staking/claimWithdraw", payload)
          .then((response) => {
            console.log("transaction", response.data);
            fetchStakeInformation();
            setPendingHash(transaction.hash);
            toast.success("Withdrawal successful!");
          })
          .catch((error) => {
            console.log("error", error.message);
            toast.error("Withdrawal failed");
          })
          .finally(() => {
            setClaimLoading(false);
          });
      } catch (error) {
        console.log("error", error.message);
        setClaimLoading(false);
        toast.error("Withdrawal failed");
      }
    }
  };
  useEffect(() => {
    if (pendingHash) {
      console.log(pendingHash, "pendingHash event");
      let listeningEvent = `wallet-transaction-status-${pendingHash}`;
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      var channel = pusher.subscribe(`wallet-transaction-${pendingHash}`);
      channel.bind(listeningEvent, function (data) {
        console.log(data, "successData");
        // if (data?.status === "confirmed") {
        //   fetchStakeInformation();
        //   authData.profileFetch();
        // }

        fetchStakeInformation();
        authData.profileFetch();
        // Please refetch the profile after getting confirmation.
        console.log(
          "pusher notification on wallet transaction",
          JSON.stringify(data)
        );
      });
      // Cleanup
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [authData, pendingHash]);

  return (
    <>
      {stakeInformation?.hasActiveStake ? (
        <Card mt={"20px"}>
          <Text
            fontSize="xl"
            color={textColorPrimary}
            mb="20px"
            ms="10px"
            fontWeight="bold"
          >
            {overviewTitle}
          </Text>
          <>
            <Flex justifyContent="space-between" mb="25px" p="4px">
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  fontSize="md"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Amount
                </FormLabel>
                <FormLabel
                  display="flex"
                  fontSize="lg"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  {Number(stakeInformation?.totalStaked ?? 0)}{" "}
                  {process.env.REACT_APP_PLATHFORM === "Questron"
                    ? "ACE"
                    : "DAPPX"}
                </FormLabel>
              </Flex>

              <Flex direction="column">
                <FormLabel
                  display="flex"
                  fontSize="md"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  {daysRemaining}
                </FormLabel>
                <FormLabel
                  display="flex"
                  fontSize="lg"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  {Number(stakeInformation?.unStakingAvailableInDays)}
                </FormLabel>
              </Flex>
              <Flex
                direction="column"
                alignItems={"center"}
                justifyContent={"center"}
              >
                {stakeInformation.hasPendingWithdraw ? (
                  <>
                    {stakeInformation.hasPendingWithdraw?.status ===
                    "pending" ? (
                      <Button
                        variant="brand"
                        w="183px"
                        fontSize="sm"
                        fontWeight="500"
                        bg={buttonColor}
                        _hover={{
                          bg: buttonHoverColor,
                        }}
                        _active={{
                          bg: buttonHoverColor,
                        }}
                        _focus={{
                          bg: buttonHoverColor,
                        }}
                        disabled={true}
                      >
                        Waiting for approval
                      </Button>
                    ) : stakeInformation.hasPendingWithdraw?.status ===
                      "approved" ? (
                      <Button
                        variant="brand"
                        w="183px"
                        fontSize="sm"
                        fontWeight="500"
                        onClick={() => {
                          handleClaim(
                            stakeInformation?.hasPendingWithdraw ?? null
                          );
                        }}
                        bg={buttonColor}
                        _hover={{
                          bg: buttonHoverColor,
                        }}
                        _active={{
                          bg: buttonHoverColor,
                        }}
                        _focus={{
                          bg: buttonHoverColor,
                        }}
                      >
                        {claimLoading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          "Claim"
                        )}
                      </Button>
                    ) : stakeInformation.hasPendingWithdraw?.status ===
                      "inProgress" ? (
                      <>
                        <Button
                          variant="brand"
                          w="183px"
                          fontSize="sm"
                          fontWeight="500"
                          bg={buttonColor}
                          _hover={{
                            bg: buttonHoverColor,
                          }}
                          _active={{
                            bg: buttonHoverColor,
                          }}
                          _focus={{
                            bg: buttonHoverColor,
                          }}
                          disabled={true}
                        >
                          {"Withdrawal in progress"}
                        </Button>
                        <Link
                          fontSize="sm"
                          mb="20px"
                          mt={"10px"}
                          color="blue.500"
                          href={`https://etherscan.io/tx/${stakeInformation?.hasPendingWithdraw?.hash}`}
                          textAlign="center"
                          target="_blank"
                        >
                          {"View on Etherscan"}
                        </Link>
                      </>
                    ) : (
                      <Text color={"red"}>Withdrawal Failed</Text>
                    )}
                  </>
                ) : (
                  <Button
                    variant="brand"
                    w="183px"
                    fontSize="sm"
                    fontWeight="500"
                    onClick={() => {
                      handleUnstakeClick();
                    }}
                    bg={buttonColor}
                    _hover={{
                      bg: buttonHoverColor,
                    }}
                    _active={{
                      bg: buttonHoverColor,
                    }}
                    _focus={{
                      bg: buttonHoverColor,
                    }}
                    disabled={
                      Number(stakeInformation?.unStakingAvailableInDays ?? 0) >
                      0
                    }
                  >
                    {submitLoading ? <Spinner /> : "Withdraw"}
                  </Button>
                )}
              </Flex>
            </Flex>
            {/* Table for Staking History */}
            <Flex direction="column" mb="25px" display="none">
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        textAlign="center"
                        style={{ textTransform: "none" }}
                        fontSize="md"
                      >
                        {capitalizeFirstLetter(t("Date"))}
                      </Th>
                      <Th
                        textAlign="center"
                        style={{ textTransform: "none" }}
                        fontSize="md"
                      >
                        {capitalizeFirstLetter(t("Type"))}
                      </Th>
                      <Th
                        textAlign="center"
                        style={{ textTransform: "none" }}
                        fontSize="md"
                      >
                        {capitalizeFirstLetter(t("Currency"))}
                      </Th>
                      <Th
                        textAlign="center"
                        style={{ textTransform: "none" }}
                        fontSize="md"
                      >
                        {capitalizeFirstLetter(t("Amount"))}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {rewards?.map((reward, index) => (
                      <Tr key={index}>
                        <Td textAlign="center" style={{ fontSize: "small" }}>
                          {moment(reward.createdAt).format("MMMM D, YYYY")}
                        </Td>
                        <Td textAlign="center" style={{ fontSize: "small" }}>
                          {reward?.source === "proSubscription"
                            ? "Deposit"
                            : "Airdrop"}
                        </Td>
                        <Td textAlign="center" style={{ fontSize: "small" }}>
                          CHI
                        </Td>
                        <Td textAlign="center" style={{ fontSize: "small" }}>
                          {Number(reward?.amount).toLocaleString()}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}
