// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import { userPrivateRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import { walletAddressFormat } from "utils/utils";
import { CopyIcon } from "@chakra-ui/icons";
import StakingReport from "./StakingReport";
import TopUpCredit from "./TopUpCredit";

const ManageSubscription = () => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await userPrivateRequest.post("/get-portal");
      setLink(result?.data?.url);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box ml="10px">
        {loading ? (
          <Text>Loading</Text>
        ) : (
          <>
            {link && (
              <Link href={link} textDecoration={"underline"} target="_blank">
                Manage Your Subscription
              </Link>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default function Settings({
  info,
  data,
  setData,
  handleSubmit,
  submitLoading,
  setSubmitLoading,
}) {
  // Chakra Color Mode
  const { t } = useTranslation();
  const history = useCustomHistory();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const { setModal } = authData;
  const [loading, setLoading] = useState(true);

  const [aggrement, setAggrement] = useState();

  const [buttonColor, setButtonColor] = useState("#121212");
  const [buttonHoverColor, setButtonHoverColor] = useState("#303030");
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("#121212");
      setButtonHoverColor("#303030");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);

  const changeForm = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    console.log(info, 515151);
    setData({
      email: info?.email,
      firstName: info?.firstName,
      lastName: info?.lastName,
      username: info?.username,
      photo: info?.photo,
      metamaskAddress: info?.metamaskAddress,
    });
  }, [info]);

  const fetchInfo = async () => {
    try {
      const result = await userPrivateRequest.get("/get-agreement");
      setAggrement(result?.data?.aggrement);
      // // console.log(result, 'result')
    } catch (err) {}
  };
  useEffect(() => {
    if (authData) {
      fetchInfo();
    }
  }, [authData]);

  const platform = process.env?.REACT_APP_PLATHFORM;

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";

  const handleUnstakeClick = () => {
    onOpen();
  };
  return (
    <>
      {Object.keys(info).length > 0 && (
        <FormControl>
          <Card>
            <Flex direction="column" mb="20px" ms="10px">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                {t("AccountSetting")}
              </Text>
              {/* <Text fontSize="md" color={textColorSecondary}>
                {t("HereYouCan")}
              </Text> */}
            </Flex>
            {configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "YES" && (
              <SimpleGrid columns={{ sm: 1 }}>
                <FormLabel
                  display="flex"
                  ms="10px"
                  fontSize="sm"
                  color={"#1b2559"}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Wallet Address
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    pt="20px"
                    pb="20px"
                    type="text"
                    fontWeight="500"
                    variant="main"
                    isReadOnly
                    _readOnly={{
                      borderColor: "#E0E5F2",
                      borderRadius: "xl",
                    }}
                    mb="25px"
                    id="email"
                    label={t("WalletAddress")}
                    placeholder={t("WalletAddress")}
                    onChange={changeForm}
                    value={walletAddressFormat(data.metamaskAddress)}
                    name="email"
                    disabled={true}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      _hover={{ bg: "#fafafa" }}
                      onClick={() => {
                        navigator.clipboard.writeText(data.metamaskAddress);
                      }}
                    >
                      <CopyIcon cursor={"pointer"} color="black" />
                    </Button>
                  </InputRightElement>
                </InputGroup>

                {/* <InputField
                    mb="25px"
                    id="email"
                    label={t("WalletAddress")}
                    placeholder={t("WalletAddress")}
                    onChange={changeForm}
                    value={walletAddressFormat(data.metamaskAddress)}
                    name="email"
                    disabled={true}

                  /> */}

                <SimpleGrid
                  columns={{ sm: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    id="username"
                    label={t("Username")}
                    placeholder={t("Username")}
                    onChange={changeForm}
                    value={data?.username}
                    name="username"
                    disabled={false}
                  />
                </SimpleGrid>
              </SimpleGrid>
            )}

            {configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "NO" && (
              <>
                <SimpleGrid
                  columns={{ sm: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    id="email"
                    label={t("EmailAddress")}
                    placeholder={t("EmailAddress")}
                    onChange={changeForm}
                    value={data.email}
                    name="email"
                    disabled={true}
                  />
                </SimpleGrid>

                <SimpleGrid
                  columns={{ sm: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    id="username"
                    label={t("Username")}
                    placeholder={t("Username")}
                    onChange={changeForm}
                    value={data?.username}
                    name="username"
                    disabled={false}
                  />
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 1, md: 2 }}
                  spacing={{ base: "20px", xl: "20px" }}
                >
                  <InputField
                    mb="25px"
                    me="30px"
                    id="first_name"
                    label={t("FirstName")}
                    placeholder={t("FirstName")}
                    onChange={changeForm}
                    value={data.firstName}
                    name="firstName"
                  />
                  <InputField
                    mb="25px"
                    id="last_name"
                    label={t("LastName")}
                    placeholder={t("LastName")}
                    onChange={changeForm}
                    value={data.lastName}
                    name={"lastName"}
                  />
                </SimpleGrid>
              </>
            )}

            <Flex
              justifyContent={{ base: "center", md: "flex-end" }}
              width="100%"
            >
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={() => {
                  handleSubmit();
                }}
                bg={buttonColor}
                // bg='#121212'
                _hover={{
                  bg: buttonHoverColor,
                  // bg: '#303030'
                }}
                _active={{
                  bg: buttonHoverColor,
                  // bg: '#303030'
                }}
                _focus={{
                  bg: buttonHoverColor,
                  // bg: '#303030'
                }}
              >
                {submitLoading ? <Spinner /> : t("SaveAndClose")}
              </Button>
            </Flex>
          </Card>

          <Card mt={"20px"}>
            <Text
              fontSize="xl"
              color={textColorPrimary}
              mb="20px"
              ms="10px"
              fontWeight="bold"
            >
              {t("YourPlan")}
            </Text>

            {authData.authState?.user?.membership === "pro" && (
              <>
                <Text
                  fontSize="lg"
                  color={textColorPrimary}
                  fontWeight="bold"
                  mb="10px"
                  ms="10px"
                >
                  {/* {t("ChinguPro2")} */}
                  {platform === "Chingu" && t("ChinguPro2")}
                  {platform === "Questron" && t("QuestronPro2")}
                </Text>
                <Text
                  fontSize="lg"
                  color={textColorPrimary}
                  fontWeight="bold"
                  mb="10px"
                  ms="10px"
                >
                  {info?.creditBalance.toLocaleString()} {creditLabel}
                </Text>
                {info?.creditBalance <= 0 && (
                  <Text
                    fontSize="lg"
                    color={"red"}
                    fontWeight="bold"
                    mb="10px"
                    ms="10px"
                  >
                    {/* {platform === "Questron"
                      ? "Insufficient Credits"
                      : "Insufficient CHIs"} */}

                    {"Insufficient " + creditLabel}
                  </Text>
                )}

                {/* <ManageSubscription /> */}

                {/* {info?.hasPendingTopup === 'yes' && (
                  <Text
                    fontSize="xl"
                    color={'green'}
                    mb="15px"
                    fontWeight="bold"
                  >
                    {info?.hasPendingTopup === 'yes'
                      ? 'Top-up in progress'
                      : ''}
                  </Text>
                )} */}

                {/* {aggrement?.billingAgreementId && (
                  <Link
                    href={`https://www.paypal.com/myaccount/autopay/connect/${aggrement?.billingAgreementId}`}
                    textDecoration={'underline'}
                    target="_blank"
                  >
                    Manage Your Subscription
                  </Link>
                )} */}
              </>
            )}
            {authData.authState?.user?.membership === "free" &&
              authData.authState?.user?.subscriptionStatus ===
                "unconfirmedPayment" && (
                <>
                  <Heading mb="15px" fontSize={"24px"} color="green">
                    Subscription in progress.
                  </Heading>
                </>
              )}
            {authData.authState?.user?.membership === "free" &&
              authData.authState?.user?.subscriptionStatus !==
                "unconfirmedPayment" && (
                <>
                  {platform === "Chingu" && (
                    <Heading mb="15px" fontSize={"24px"}>
                      {t("ChinguFree")}
                    </Heading>
                  )}

                  {platform === "Questron" && (
                    <Heading mb="15px" fontSize={"24px"}>
                      {t("QuestronFree")}
                    </Heading>
                  )}

                  {platform === "DappStoreAi" && (
                    <Heading mb="15px" fontSize={"24px"}>
                      {t("DappxFree")}
                    </Heading>
                  )}

                  <Box textAlign={"left"}>
                    {platform === "Chingu" && (
                      <button
                        textDecoration={"underline"}
                        className="underline"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        {t("SubscribetoChinguPro")}
                      </button>
                    )}
                  </Box>
                </>
              )}
          </Card>

          {authData.authState?.user?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
              "YES" && <TopUpCredit />}
          {authData.authState?.user?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "YES" && <StakingReport data={authData.authState?.user} />}
        </FormControl>
      )}
    </>
  );
}
